/** @jsx jsx **/
import { jsx } from 'theme-ui'
import { FC } from 'react'
import SizeGuideTemplate from '~/templates/page-size-guide'
import { graphql, useStaticQuery } from 'gatsby'
import { SizeGuideQueryResult } from '~/@types/models'

type Props = {}

type QueryResult = {
  pageData: SizeGuideQueryResult
}

const SizeGuidePage: FC<Props> = () => {
  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "sizeGuide" } }) {
        frontmatter {
          title
          roomDetails {
            title
            rooms {
              id
              name
              title
              options {
                description
                image {
                  ...sizeGuideImage
                }
              }
            }
          }
        }
        html
      }
    }
  `)

  return <SizeGuideTemplate data={query.pageData} />
}

export default SizeGuidePage
