/** @jsx jsx **/
import { jsx, Box, SxStyleProp } from 'theme-ui'
import { FC } from 'react'

type Props = {
  sxProps?: SxStyleProp
}

const Dash: FC<Props> = ({ sxProps }) => {
  return (
    <Box
      sx={{
        bg: 'text',
        width: '40px',
        height: '2px',
        ...sxProps,
      }}
    />
  )
}

export default Dash
