/** @jsx jsx **/
import { Box, jsx, ThemeUICSSObject } from 'theme-ui'
import { FC } from 'react'
import MarkdownSerializer from 'markdown-it'

type Props = {
  content: string
  contentSx?: ThemeUICSSObject
  options?: {
    html: boolean
    linkify: boolean
    typographer: boolean
  }
}

const MarkdownIt: FC<Props> = ({
  content,
  contentSx,
  options = {
    html: true,
    linkify: true,
    typographer: true,
  },
}) => {
  const md = new MarkdownSerializer(options)
  return (
    <Box
      sx={{ ...contentSx }}
      dangerouslySetInnerHTML={{
        __html: md.render(content),
      }}
    />
  )
}

export default MarkdownIt
