/** @jsx jsx **/
import { jsx, Heading, Text, Box, Grid, Container } from 'theme-ui'
import { ChangeEvent, FC, useState } from 'react'
import Layout from '~/components/layout'
import theme, { space } from '~/gatsby-plugin-theme-ui'
import { Select } from '~/components/select'
import GatsbyImage from 'gatsby-image'
import { SizeGuideQueryResult } from '~/@types/models'
import MarkdownIt from '~/components/MarkdownIt'
import Dash from '~/components/dash'
import HeadingV2 from '~/componentsV2/heading'

type Props = {
  data: SizeGuideQueryResult
}

const SizeGuideTemplate: FC<Props> = ({ data }) => {
  const {
    frontmatter: { roomDetails, title },
    html,
  } = data

  return (
    <Layout headerVariant="default" title={title} description={title}>
      <Container
        mt={[space.l, null, space.xl2]}
        mb={[0, null, space.xl2]}
        variant="containerPrimary"
      >
        <HeadingV2
          sx={{
            textAlign: 'center',
            mb: [space.gridGap3, null, space.l2],
          }}
        >
          {title}
        </HeadingV2>
        <Text variant="regular" mb={[space.l, null, space.xl1]}>
          <MarkdownIt content={html} />
        </Text>
        <Heading as="h2" variant="title5">
          {roomDetails.title}
        </Heading>
        <Dash
          sxProps={{
            my: [space.gridGap2, null, space.gridGap2],
          }}
        />
        <RoomDetailsSelector roomDetails={roomDetails} />
      </Container>
    </Layout>
  ) as JSX.Element
}

type RoomDetailsSelectorProps = {
  roomDetails: SizeGuideQueryResult['frontmatter']['roomDetails']
}

const RoomDetailsSelector: FC<RoomDetailsSelectorProps> = ({ roomDetails }) => {
  const rooms = roomDetails.rooms.map(room => room.name)
  const sizes = [`6' x 9'`, `8' x 10'`, `9' x 12'`, `10' x 14'`, `12' x 15'`]
  const [selectedRoom, setSelectedRoom] = useState(0)
  const [selectedSize, setSelectedSize] = useState(0)

  const handleChange = (e: ChangeEvent<HTMLSelectElement>, type: string) => {
    if (type === 'room') {
      setSelectedRoom(rooms.indexOf(e.target.value))
    }
    if (type === 'size') {
      setSelectedSize(sizes.indexOf(e.target.value))
    }
  }
  return (
    <Box>
      <Grid columns={['1fr', null, '1fr 1fr']} gap={space.gridGap2} mb={[space.l, null, space.xl1]}>
        <Select options={rooms} label="Select Room" onChange={e => handleChange(e, 'room')} />
        <Select options={sizes} label="Rug Size" onChange={e => handleChange(e, 'size')} />
      </Grid>
      <Container
        p={0}
        sx={{
          maxWidth: 576,
        }}
      >
        <Heading as="h3" variant="title5" mb={space.md}>
          {roomDetails.rooms[selectedRoom].title}
        </Heading>
        <Text
          variant="small"
          color={theme.colors.muted}
          mb={[space.md, null, space.xl]}
          sx={{
            a: {
              color: 'inherit',
            },
          }}
        >
          <MarkdownIt
            content={roomDetails.rooms[selectedRoom].options[selectedSize]?.description}
          />
        </Text>
        <Box mx={[space.md1n, null, 0]}>
          <GatsbyImage
            fluid={
              roomDetails.rooms[selectedRoom].options[selectedSize].image.childImageSharp.fluid
            }
          />
        </Box>
      </Container>
    </Box>
  ) as JSX.Element
}

export default SizeGuideTemplate
